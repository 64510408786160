<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <div class="about_tab_content">
      <div class="row mt-4">
        <div class="col-12 col-md-12 col-lg-6 col-xl-8">
          <div class="card">
            <div class="card-body">
              <section class="vid-title-sec">
                <div class="container">
                  <div
                    class="alert"
                    :class="`alert-${alertBox.variant}`"
                    v-if="alertBox.status"
                  >
                    {{ alertBox.message }}
                  </div>
                  <form @submit.prevent="SEND_TO_UPDATE_CUSTOMER_DETAIL">
                    <div class="bg-light card">
                      {{ $t("updateUserHintWarn") }}
                    </div>
                    <div class="vid-title my-2">
                      <h2 class="title-hd">{{ $t("email") }}</h2>
                      <div class="form_field">
                        <input
                          type="email"
                          maxlength="100"
                          required
                          v-model="form.email"
                          :placeholder="$t('addHere')"
                        />
                      </div>
                    </div>
                    <div class="vid-title my-2">
                      <h2 class="title-hd">{{ $t("userNameAndLastname") }}</h2>
                      <div class="form_field">
                        <input
                          type="text"
                          maxlength="100"
                          required
                          v-model="form.fullname"
                          :placeholder="$t('addHere')"
                        />
                      </div>
                    </div>
                    <div class="vid-title my-2">
                      <h2 class="title-hd">{{ $t("phone") }}</h2>
                      <div class="form_field">
                        <input
                          type="text"
                          maxlength="21"
                          pattern="\+[0-9]{0,20}$"
                          :title="$t('invalidPhoneRegex')"
                          required
                          v-model="form.phone"
                          :placeholder="$t('phonePlaceholder')"
                        />
                      </div>
                      <div>
                        <small class="text-info">{{
                          $t("phonePlaceholder")
                        }}</small>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div
                      class="alert"
                      :class="`alert-${alertBox.variant}`"
                      v-if="alertBox.status"
                    >
                      {{ alertBox.message }}
                    </div>
                    <div class="btn-sbmit">
                      <button type="submit" :disabled="userIsSending">
                        <loading v-if="userIsSending"></loading>
                        <span v-else> {{ $t("update") }} </span>
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 col-xl-4">
          <div class="card">
            <div class="card-body">
              <h5 class="mb-4">
                {{ user.roleId === 3 ? $t("instructorInfo") : $t("userInfo") }}
              </h5>
              <p>
                <strong>{{ $t("userNameAndLastname") }}:</strong>
                {{ user.fullname }}
              </p>
              <p>
                <strong>{{ $t("email") }}:</strong> {{ user.email }}
              </p>
              <p>
                <strong>{{ $t("phone") }}:</strong> {{ user.phone }}
              </p>
              <p>
                <strong class="mr-2">{{ $t("joinedArea") }}:</strong>
                <img
                  :src="`https://flagicons.lipis.dev/flags/4x3/${
                    user?.langCode == 'undefined'
                      ? 'tr'
                      : user?.langCode?.toLowerCase() || 'tr'
                  }.svg`"
                  :title="user.langCode?.toLowerCase() || 'tr'"
                  width="30"
                  :alt="user.langCode"
                />
              </p>
              <p>
                <strong>{{ $t("createdAt") }}:</strong>
                {{ formatter(new Date(user.createdAt)) }}
              </p>
            </div>
          </div>
          <div class="mt-4">
            <div class="card">
              <div class="card-body">
                <h5 class="mb-4">{{ $t("certificateApproval") }}</h5>
                <img
                  :src="$api.image(user.verifyFilePath)"
                  alt=""
                  width="100%"
                  v-if="user.verifyFilePath !== null"
                />
                <p v-else>{{ $t("noApprovalCertificate") }}</p>
              </div>
            </div>
          </div>
          <!-- <div class="mt-4">
            <div class="card">
              <div class="card-body">
                <h5 class="mb-4">{{ $t("certificateApproval") }}</h5>
                <img
                  :src="$api.image(user.verifyFilePath)"
                  alt=""
                  width="100%"
                  v-if="user.verifyFilePath !== null"
                />
                <p v-else>{{ $t("noApprovalCertificate") }}</p>
              </div>
            </div>
          </div> -->
          <mock-datas></mock-datas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import MockDatas from "./MockDatas.vue";
import Mail from "../../mixins/Mail";

export default {
  mixins: [Mail],
  components: {
    MockDatas,
  },
  props: {
    user: {
      default: () => {
        return {
          fullname: "",
        };
      },
    },
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  data() {
    return {
      alertBox: { status: false },
      userIsSending: false,
      form: {
        email: "",
        fullname: "",
        phone: "",
        id: 0,
      },
    };
  },
  methods: {
    formatter,
    SEND_EMAIL_TO_ADDED_USER(form) {
      this.$store.commit("SET_MAIL_DRAWER", {
        status: true,
        form: {
          to: form.email,
          bcc: "",
          subject: this.$t("sendEmailToUpdatedUserSubject", {
            fullname: form.fullname,
            company: this.getSettings.title,
          }),
          content: this.$t("sendEmailToUpdatedUserContent", {
            ...this.form,
            website: this.getSettings.website,
            company: this.getSettings.title,
          }),
          files: [],
        },
      });
    },
    async SEND_TO_UPDATE_CUSTOMER_DETAIL(e) {
      const reg = new RegExp(/\+[0-9]{0,21}$/);
      const check = reg.test(this.form.phone);
      if (!check)
        return (this.alertBox = {
          status: true,
          message: this.$t("invalidPhoneRegex"),
          variant: "danger",
        });
      this.alertBox.status = false;
      e.preventDefault();
      const fd = new FormData();
      fd.append("Email", this.form.email);
      fd.append("Fullname", this.form.fullname);
      fd.append("Phone", this.form.phone);
      fd.append("IsActive", this.form.isActive);
      this.userIsSending = true;
      this.alertBox = await this.$store.dispatch(
        "SEND_UPDATE_CUSTOMER_DETAIL",
        {
          fd,
          form: this.form,
        }
      );
      this.userIsSending = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          this.SEND_EMAIL_TO_ADDED_USER(this.form);
          this.$store.commit("SET_CUSTOMER_LIST_IS_LOADING", true);
        }
        setTimeout(() => {
          this.$store.commit("SET_CUSTOMER_LIST_IS_LOADING", false);
        }, 1000);
      }, 2500);
    },
  },
  mounted() {
    this.form = { ...this.user };
  },
};
</script>

<style></style>
