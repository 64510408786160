<template>
  <div>
    <div class="mt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-4">{{ $t("extraInformation") }}</h5>

          <div v-for="(definition, i) in allDefinitions" :key="i">
            <strong>{{ definition.activeDefiniton.label }}: </strong>
            <span v-if="!definition.link"> {{ definition.value }}</span>
            <a
              v-else
              :href="$api.image(definition.value)"
              class="tel-link"
              target="_blank"
            >
              <button class="btn btn-pill subscribe btn-xs ml-2">
                {{ $t("clickHereForViewFile") }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allDefinitions: [],
    };
  },
  computed: {
    activeLanguage() {
      return this.$i18n.locale;
    },
    dataId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async GET_MOCKDATAS() {
      const response = await this.$api.get(
        `MockDatas?dataId=${this.dataId}&dataType=User`
      );

      this.allDefinitions = response.data.map((def) => ({
        ...def,
        link: def.value.includes("/MockDatas/"),
        activeDefiniton: def.definitions.find(
          (e) => e.language === `${this.activeLanguage}`
        ),
      }));
    },
  },
  mounted() {
    this.GET_MOCKDATAS();
  },
};
</script>

<style scoped></style>
